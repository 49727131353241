import axios from "axios";

class BffService {
  constructor() {
    this.pages = [];
    this.pageContent = [];
    this.services = {};
    /* https://mpl-bff-dot-parkos-production.appspot.com/ */
    this.axiosInstance = axios.create({
      baseURL: process.env.BFF_BASE_URL,
    });

    this.axiosInstance.interceptors.request.use(
      (request) => request,
      (error) => {
        console.log('BFF Request Error: ', JSON.stringify(error));
        return error;
      },
    );
    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        console.log('BFF Response error: ', JSON.stringify(error));
        return error;
      },
    );
    this.refreshes = {
      pageContent: false,
      services: false,
    };
  }

  refresh = function () {
    this.refreshes.pageContent = true;
    this.refreshes.services = true;
  };

  getPageContent(devtitle) {
    const self = this;

    if (!self.pageContent[devtitle] || self.refreshes.pageContent === true) {
      self.refreshes.pageContent = false;

      const fetch = new Promise((resolve, reject) => {
        self.axiosInstance
          .get(`pages/${devtitle}/content.json`)
          .then((response) => {
            self.pageContent[devtitle] = response.data;
            resolve(self.pageContent[devtitle]);
          })
          .catch((e) => {
            reject(e);
          });
      });

      return fetch;
    }

    return new Promise((resolve) => {
      resolve(self.pageContent[devtitle]);
    });
  }

  async getAirportReviews(airport, lang, limit = 10) {
    const { axiosInstance } = this;
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams();
      if (airport) {
        params.set("airport", airport);
      }
      params.set('lang', lang);
      params.set('limit', limit);
      axiosInstance
        .get(`airport/reviews?${params.toString()}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getAirportReviewsV2(airport, lang, limit = 10) {
    const params = new URLSearchParams();

    params.append('rating[gte]', 8);

    if (lang) params.append('lang', lang);
    if (limit) params.append('size', limit);

    const res = await this.axiosInstance.get(
      `airport/reviews-v2?${params.toString()}`,
    );
    return res.data;
  }

  getService = function (service) {
    if (this.services[service] && !this.refreshes.services) {
      return this.services[service];
    }
    this.refreshes.services = false;
    const self = this;
    return new Promise((resolve, reject) => {
      self.axiosInstance
        .get(`services/${service}`)
        .then((response) => {
          self.services[service] = response.data;
          resolve(self.services[service]);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  async getHeaderAirportsConfiguration() {
    const { axiosInstance } = this;
    return new Promise((resolve, reject) => {
      axiosInstance
        .get('configurations/header')
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getLanguages(limit = 50) {
    const self = this;
    const { data } = await self.axiosInstance.get('languages', {
      params: { limit },
    });
    return data?.data;
  }

  async getHealthz() {
    const self = this;
    const { data } = await self.axiosInstance.get('healthz');
    return data;
  }

  async getTranslations(language) {
    const self = this;
    const { data } = await self.axiosInstance.get(
      `translations/${language}/airport`,
    );
    return data;
  }

  async getCountryStatistics(lang) {
    const self = this;
    const { data } = await self.axiosInstance.get("country/statistics", {
      params: { lang },
    });
    return data;
  }

  async getAirports(lang, limit = 250, orderBy = 'locations_content.name') {
    const self = this;
    const { data } = await self.axiosInstance.get("airports", {
      params: { lang, limit, orderBy },
    });

    return data.data;
  }

  // eslint-disable-next-line class-methods-use-this
  getAirport(slug, airports) {
    return airports.find((airport) => airport.slug === slug);
  }

  async getAirportData(airport) {
    const self = this;
    const { data } = await self.axiosInstance.get(
      `airports/${airport.id}/details`,
    );
    return data;
  }

  async getAirportFaq(lang, airport) {
    const self = this;
    const { data } = await self.axiosInstance.get(
      `airports/${airport.id}/faq`,
      { params: { lang } },
    );
    return data;
  }

  async getAirportParkings(lang, airport) {
    const self = this;
    const response = await self.axiosInstance.get("parkings", {
      params: { lang, airport: airport.id },
    });

    return response.data;
  }

  async getPageTemplate(slug, lang) {
    const self = this;
    const { data } = await self.axiosInstance.get("page_template", {
      params: {
        slug,
        lang,
      },
    });
    return data?.data[lang];
  }

  async getTop3Air(lang) {
    const self = this;
    const { data } = await self.axiosInstance.get(
      `top3-airports`,
      { params: { lang } },
    );
    return data.data;
  }
}

const bffInstances = {};

function getInstance(name, config) {
  if (!(name in bffInstances)) {
    bffInstances[name] = new BffService(config);

    setInterval(() => {
      bffInstances[name].refresh();
    }, 60000);
  }

  return bffInstances[name];
}

export { getInstance };
